@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "mixins" as *;

// NOTE referenced file: libs/shared/styles/components/_chips.scss
.chip {
    border-width: 2px;
    font-size: 0.875rem;
    gap: 8px;
    height: 40px;
    padding: 0 16px;
    line-height: 1em;
}

// NOTE referenced file: libs/shared/styles/components/_buttons.scss
button {
    --button-font-size: 1rem;
    --button-line-height: 150%;
}

// NOTE referenced file: "libs/shared/ui/src/lib/components/label-value/label-value.component.scss"
dtm-ui-label-value {
    --label-font-size: 1rem;
    --label-value-font-size: 1rem;
}

// NOTE referenced file: "libs/shared/ui/src/lib/components/form-field/form-field.component.scss"
dtm-ui-form-field {
    --form-field-font-size: 1rem;
}

dtm-ui-checkbox-field,
dtm-ui-files-upload-field,
dtm-ui-form-errors,
dtm-ui-form-field,
dtm-ui-image-picker,
dtm-ui-radio-field {
    --typography-body-hint-font-size: 0.75rem;
    --input-hint-min-height: 20px;
}

dtm-ui-radio-field {
    --radio-field-label-font-size: 1rem;
}

// NOTE override mat-menu
.cdk-overlay-pane {
    div.mat-mdc-menu-panel {
        max-width: none;
    }
}

// NOTE custom class for alerts mat-menu to move it exactly on top of the alert item
.mat-menu-panel.alert-menu {
    margin: 12px -4px;
}

// NOTE override mat-tooltip
mat-tooltip-component {
    .mat-mdc-tooltip {
        --mdc-plain-tooltip-container-color: #{$color-primary-50};
        --mdc-plain-tooltip-supporting-text-color: #{$color-gray-700};

        .mdc-tooltip__surface {
            --typography-body-hint-font-size: 0.75rem;
            @include body-hint;
            font-weight: $typography-weight-bold;
        }
    }
}

// NOTE override tooltip-panel to add arrows
.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #{$color-primary-50};
        border-radius: 2px;
        z-index: -1;
        @include box-shadow-dark;
    }

    &-above .mat-mdc-tooltip::after {
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%) rotate(45deg);
    }

    &-right .mat-mdc-tooltip::after {
        top: 50%;
        left: -4px;
        transform: translateY(-50%) rotate(45deg);
    }

    &-below .mat-mdc-tooltip::after {
        left: 50%;
        top: -4px;
        transform: translateX(-50%) rotate(45deg);
    }

    &-left .mat-mdc-tooltip::after {
        top: 50%;
        right: -4px;
        transform: translateY(-50%) rotate(45deg);
    }
}

// NOTE override mat-slide-toggle
mat-slide-toggle {
    &.mat-mdc-slide-toggle {
        --dtm-slide-toggle-width: 56px;
        --dtm-slide-toggle-height: 32px;
        --dtm-slide-toggle-handle-padding: 4px;
    }
}

// NOTE referenced file: "libs/shared/ui/src/lib/components/confirmation-dialog/confirmation-dialog.component.scss"
dtm-ui-confirmation-dialog .confirmation-text {
    @include body-16;
}

// NOTE override iconfont size
.iconfont {
    font-size: 1.5rem;
}

// NOTE referenced file: libs/shared/ui/src/lib/components/select-field/select-field.component.scss
dtm-ui-select-field {
    --select-field-trigger-button-width: 1.5rem;
    --select-field-trigger-button-height: 1.5rem;
}

// NOTE referenced file: libs/shared/ui/src/lib/components/input-field/input-field.component.scss
dtm-ui-input-field {
    --input-field-clear-button-width: 1.5rem;
    --input-field-clear-button-height: 1.5rem;
}

// NOTE referenced file: libs/shared/ui/src/lib/components/expanded-number-input/expanded-number-input.component.scss
dtm-ui-expanded-number-input {
    --expanded-number-input-text-align: center;
    --expanded-number-input-option-font-size: 1rem;
}

.mat-menu-panel[role="menu"] .mat-menu-item {
    @include body-16;
}

// NOTE override mat-mdc-autocomplete-panel
.cdk-overlay-pane .mat-mdc-autocomplete-panel.mdc-menu-surface[role="listbox"] {
    border-radius: 6px;
}

// NOTE override mat-option
mat-option {
    --mat-option-hover-state-layer-color: #{$color-secondary-50};
    --mat-option-selected-state-layer-color: #{$color-secondary-50};
    --mat-option-selected-state-label-text-color: #{$color-gray-900};
}
