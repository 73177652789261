@use "libs/shared/styles/style-guide-colors" as *;

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
    background-color: rgba($color-gray-900, 0.4);

    div {
        background-color: rgba($color-gray-900, 0.8);
    }
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;

    div {
        width: 30px;
        height: 30px;
        margin-left: 5px;
        margin-top: 5px;

        text-align: center;
        border-radius: 15px;
    }

    span {
        line-height: 30px;
        color: $color-white;
    }
}
