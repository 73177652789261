@use "libs/shared/styles/app-theme";
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/mixins" as *;

body {
    font-size: $typography-size-body-default;
    overflow: hidden;
}

mat-dialog-container.mat-dialog-container {
    padding: 20px 24px 24px;
    border-radius: 6px;
    max-width: calc(100vw - 32px);
}

.flight-marker-highlighted {
    border: 8px solid $color-white;
    border-radius: 100%;

    @include box-shadow-dark;
}

.flight-marker-highlighted-selected {
    border: 6px solid $color-white;
    border-radius: 100%;
    // NOTE: to achieve blue and white border effect for selected flight we add shadow with reduced border-width
    box-shadow: 0 0 0 2px $color-secondary-700, -1px 0 10px rgba($color-gray-900, 0.08), 0 1px 18px rgba($color-gray-900, 0.12),
        0px 3px 5px rgba($color-gray-900, 0.2);
}

.leaflet-marker-icon.marker-cluster {
    background-color: $color-white;

    > div {
        background-color: $color-white;
        margin: 0;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        > span {
            @include heading-5;

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $color-gray-700;
        }
    }
}
